'use strict';

(function() {
  class ListBladeCtrl {
    constructor($scope, Util, Auth) {
      this.bladePositionMap = {
      	'A': 1,
      	'B': 2,
      	'C': 3
      };
      this.Util = Util;
      this.turbine = $scope.turbine;
      this.turbine.blades = _.sortBy($scope.turbine.blades, blade => {
        return this.bladePositionMap[blade.position]?this.bladePositionMap[blade.position]:blade.position;
      });
      this.isAdmin = Auth.isAdmin;
      this.sharedLevel = localStorage.getItem('sharedLevel');
      this.bladeCount = $scope.bladeCount;
    }
    getBladePosition(position) {
    	return this.bladePositionMap[position]?this.bladePositionMap[position]:position;
    }

    getClass(finding) {
      if(finding)
        return this.Util.getFindingClass(finding);
    }
    getBladePositionClass(position) {
      if(position)
        return `position-${position}`;
    }
    checkUncheckBlades(checkedValue, positionNumber, position) {
      let chekedPositionBlades = _.filter(this.turbine.blades, (blade) => {
        return blade.position == positionNumber || blade.position == position;
      });
      for (let i = 0; i < chekedPositionBlades.length; i++) {
        let blade = chekedPositionBlades[i];
        blade.checked = checkedValue;
      }
      jQuery(`.position-${positionNumber}`).prop('checked', checkedValue);
      for (let i = 1; i <= 3; i++) {
        if (i != positionNumber) {
          jQuery(`.position-${i}`).attr("disabled", checkedValue);
        }
      }
    }
  }

  angular.module('windmanagerApp')
    .controller('ListBladeCtrl', ListBladeCtrl);
})();
